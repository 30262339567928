const Tag = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="0.75rem" height="0.75rem" viewBox="0 0 12 12" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.57692C12 7.87567 11.8812 8.16242 11.6701 8.37379L8.37358 11.6703C8.1622 11.8814 7.87567 12 7.57692 12C7.27818 12 6.99143 11.8812 6.78006 11.6701L0.241295 5.13133C0.157813 5.05014 0.0931182 4.95162 0.0517979 4.84268C0.00966974 4.73162 -0.0070948 4.61254 0.0027309 4.49416C0.00368067 4.48272 0.00503091 4.47131 0.00677906 4.45996L0.503848 1.23322C0.520822 1.04584 0.602944 0.869935 0.73644 0.73644C0.869935 0.602944 1.04584 0.520822 1.23322 0.503848L4.45996 0.00677906C4.47131 0.00503091 4.48272 0.00368067 4.49416 0.0027309C4.61254 -0.0070948 4.73162 0.00966974 4.84268 0.0517979C4.95162 0.0931182 5.05014 0.157813 5.13133 0.241295L11.6703 6.78027C11.8814 6.99165 12 7.27818 12 7.57692ZM2.63639 3.55189C2.63639 3.04628 3.04627 2.6364 3.55188 2.6364C4.05749 2.6364 4.46737 3.04628 4.46737 3.55189C4.46737 4.0575 4.05749 4.46738 3.55188 4.46738C3.04627 4.46738 2.63639 4.0575 2.63639 3.55189Z"
        fill="#1C1C1C"
      />
    </svg>
  );
};

export default Tag;
