import { Container } from '@chakra-ui/react';

import { CartSummary } from '$components/CartSummary';

type Props = {
  children: React.ReactNode;
};

export const Layout = ({ children }: Props) => {
  return (
    <Container
      as="main"
      flex="1 1 auto"
      overflow="auto"
      p="0"
      m="0"
      height="100%"
      display="flex"
      flexDirection="column"
      overscrollBehavior="none"
      maxW="none"
    >
      <CartSummary />
      {children}
    </Container>
  );
};
