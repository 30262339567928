import { ChevronRightIcon, CloseIcon } from '@chakra-ui/icons';
import { Box, Divider, Flex, HStack, IconButton, Text, VStack } from '@chakra-ui/react';
import { formatPrice } from '@getjust/commons';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo } from 'react';

import { FormLoader } from '$components/FormLoader';
import IconWithBadge from '$components/IconWithBadge';
import ShippingLogo from '$components/SvgLogos/ShippingLogo';
import Tag from '$components/SvgLogos/Tag';
import { useCart, useShop } from '$hooks/queries';
import { usePostMessage } from '$src/hooks/client/usePostMessage';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { useShowCartAtom } from '$src/hooks/state/useShowCartAtom';
import { captureMixpanelAnalytics, capturePosthogAnalytics } from '$src/utils';

export const CartSummary = () => {
  const { data: shop } = useShop();
  const { data: cart } = useCart();
  const { toggle } = useShowCartAtom();
  const { t } = useTranslation(['common']);
  const { postMessage } = usePostMessage();
  const { track } = useTrackAmplitude();

  // NOTE: Discount code / title should fit in one line
  const discountTitleFontSize = useMemo(() => {
    const sizeLength =
      (cart?.discounts
        ? cart.discounts.length === 1
          ? cart.discounts[0]?.code?.length
          : cart.discounts.length > 1
          ? `${cart?.discounts.length} ${t('commons.discount_code').toUpperCase()}`.length
          : t('notAuthed.promoCode').length
        : t('notAuthed.promoCode').length) ?? 0;
    return sizeLength > 10 && window.innerWidth < 340 ? '3xs' : 'sm';
  }, [cart?.discounts, t]);

  const showLoader = useMemo(() => !shop || !cart, [shop, cart]);
  const onBack = async () => {
    capturePosthogAnalytics('LEAVE');
    captureMixpanelAnalytics('LEAVE');

    await track('Checkout form closed')?.promise;

    return postMessage({
      emitter: 'JUST_FORM',
      action: 'CLOSE',
    });
  };

  const onClickCartSummary = useCallback(() => {
    track('Cart summary opened', { value: 'Cart summary' });
    toggle();
  }, [toggle, track]);

  const shippingMessage = useMemo(() => {
    if (cart?.estimatedShipping?.amount) {
      return `${formatPrice(cart.estimatedShipping.amount, {
        currency: cart?.estimatedShipping.currency ? cart.estimatedShipping.currency : 'EUR',
      })} ${t('notAuthed.estimatedShipping')}`;
    }
    if (!cart?.shipping.address) {
      return t('addressRequired');
    }
    if (cart?.totalShipping === 0) {
      return t('notAuthed.freeShipping');
    }
    if (cart?.shipping.selected) {
      return `${formatPrice(cart?.totalShipping, {
        currency: cart?.currency ? cart.currency : 'EUR',
      })} ${t('notAuthed.shipping')}`;
    }
    return t('addressRequired');
  }, [cart, t]);

  if (showLoader) {
    return <FormLoader />;
  }

  return (
    <>
      <Box bgColor="white" zIndex={5}>
        <Flex py={1} px={4} height="60px" alignItems="center" position="relative">
          <Flex direction="column" width="100%" justifyContent="center" alignItems="center">
            <Text color="#1C1C1C" fontSize="1rem" fontStyle="normal" fontWeight="medium" lineHeight="6">
              {t('commons.checkout_title')}
            </Text>
            <Text color="#585858" fontSize="xs" fontWeight="normal" lineHeight="4" textAlign="center">
              {shop?.name ?? ''}
            </Text>
          </Flex>

          <IconButton aria-label="Close" variant="ghost" size="xs" icon={<CloseIcon />} onClick={onBack} />
        </Flex>
        <VStack
          cursor="pointer"
          onClick={onClickCartSummary}
          px={4}
          py={3}
          flexDirection="column"
          alignItems="flex-start"
          bg="#FFF"
          borderRadius="3xl"
          mx={2}
          mb={2}
          boxShadow="2px 2px 10px 0px rgba(0, 0, 0, 0.05), -2px -2px 12px 0px rgba(0, 0, 0, 0.05)"
          spacing={1}
        >
          <HStack alignItems="center" gap="3">
            <IconWithBadge
              imageUrl="/images/cart.svg"
              badgeText={cart?.lineItems?.reduce((prev, next) => prev + next.quantity, 0) ?? 0}
              alt="cart icon"
            />

            <HStack alignItems="center" spacing={2}>
              {cart?.totalDiscount ? (
                <>
                  <Text color="#585858" textAlign="center" fontSize="md" lineHeight="6">{`-${formatPrice(
                    cart?.totalDiscount,
                    {
                      currency: cart?.currency ? cart?.currency : 'EUR',
                    },
                  )}`}</Text>
                  <HStack py={1} px={1.5} backgroundColor="brand.green" borderRadius="base" spacing={1}>
                    <Tag />
                    {cart?.discounts && (
                      <Text color="#1C1C1C" fontSize="sm" lineHeight="5" fontWeight="semibold">
                        {`${cart?.discounts.length} ${t('commons.discount_code').toUpperCase()}`}
                      </Text>
                    )}
                  </HStack>
                  <Divider
                    orientation="vertical"
                    width="0.0625rem"
                    height="0.75rem"
                    background="#8F8F8F"
                    backgroundColor="#8F8F8F"
                    borderRadius={1}
                  />
                </>
              ) : (
                <>
                  <HStack py={1} px={1.5} backgroundColor="#E0E0E0" borderRadius="base" spacing={1}>
                    <Tag />
                    <Text
                      color="#1C1C1C"
                      fontSize={discountTitleFontSize}
                      lineHeight="5"
                      fontWeight="semibold"
                    >
                      {t('notAuthed.promoCode')}
                    </Text>
                  </HStack>
                  <Divider
                    orientation="vertical"
                    width="0.0625rem"
                    height="0.75rem"
                    background="#8F8F8F"
                    backgroundColor="#8F8F8F"
                    // color="#8F8F8F"
                    borderRadius={1}
                  />
                </>
              )}
              {cart?.totalAmount !== undefined && (
                <Text color="#1C1C1C" textAlign="center" fontWeight="bold" lineHeight="6">{`${formatPrice(
                  cart?.totalAmount,
                  { currency: cart.currency ?? 'EUR' },
                )} Total`}</Text>
              )}
            </HStack>
          </HStack>

          <HStack display="flex" justifyContent="space-between" w="100%">
            <HStack alignItems="center" gap="3">
              <Box
                width="2.25rem"
                height="2.25rem"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                flexShrink="0"
                borderRadius="3xl"
                border="1px solid rgba(0, 0, 0, 0.02)"
              >
                <Box
                  boxSize="1.5rem"
                  ml={1}
                  mt={1}
                  alignContent="center"
                  alignItems="center"
                  alignSelf="center"
                >
                  <ShippingLogo />
                </Box>
              </Box>
              <Text color="#1C1C1C" textAlign="left" lineHeight="6" pl={0.5} flex="1">
                {shippingMessage}
              </Text>
            </HStack>
            <ChevronRightIcon boxSize="1.5rem" />
          </HStack>
        </VStack>
      </Box>
    </>
  );
};
